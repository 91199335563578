import {
    CreateButton,
    ExportButton,
    ListActionsProps,
    sanitizeListRestProps, SelectColumnsButton,
    TopToolbar,
    useListContext,
} from 'react-admin'
import React, { cloneElement } from 'react'

export type ExtraListActionsProps = {
    children?: React.ReactElement,
    isConfigurable?: boolean,
} & ListActionsProps
export const DefaultListActions = ({ children, isConfigurable, ...others }: ExtraListActionsProps) => {
    const { className, exporter, hasCreate, filters, ...rest } = others
    const { sort, resource, displayedFilters, filterValues, showFilter, total } = useListContext()
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {isConfigurable && <SelectColumnsButton color={'primary'}/>}
            {children}
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <CreateButton />
            {exporter && <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={sort}
                filterValues={filterValues}
                maxResults={total}
            />
            }
        </TopToolbar>
    )
}