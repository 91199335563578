import { fetchJson } from './fetch'
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE } from './types'
import { stringify } from 'querystring'

function parseResponse(json) {
    //check if pagination
    if (json.content != null) {
        return {
            data: json.content,
            total: json.totalElements,
        }
    } else {
        return {
            data: json,
            total: json.length,
        }
    }
}

/**
 * Maps admin-on-rest queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 * DELETE_MANY  => DETETE http://my.api.url/posts/123,124,125
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (apiUrl, httpClient = fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = ''
        const options = {}
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination
                const { field, order } = params.sort

                const query = {
                    sort: `${field},${order}`,
                    size: perPage,
                    page: page - 1,
                    filter: JSON.stringify(params.filter),
                }
                options.method = 'GET'
                url = `${apiUrl}/${resource}/get?${stringify(query)}`
                break
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/getOne/${params.id}`
                break
            case GET_MANY: {
                url = `${apiUrl}/${resource}/get/${params.ids}`
                break
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination
                const { field, order } = params.sort
                const query = {
                    sort: `${field},${order}`,
                    size: perPage,
                    page: page - 1,
                    filter: JSON.stringify({
                        ...params.filter,
                        [params.target]: params.id,
                    }),
                }
                options.method = 'GET'
                url = `${apiUrl}/${resource}/get?${stringify(query)}`
                break
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/update/${params.id}`
                options.method = 'PUT'
                options.body = JSON.stringify(params.data)
                break
            case CREATE:
                const path = 'insert'
                url = `${apiUrl}/${resource}/${path}`
                options.method = 'POST'
                options.body = JSON.stringify(params.data)
                break
            case DELETE:
                url = `${apiUrl}/${resource}/delete/${params.id}`
                options.method = 'DELETE'
                break
            case DELETE_MANY:
                url = `${apiUrl}/${resource}/delete/${params.ids}`
                options.method = 'DELETE'
                break

            default:
                throw new Error(`Unsupported fetch action type ${type}`)
        }
        options.headers = new Headers({ Accept: 'application/json' })
        return { url, options }
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const { json } = response

        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                let finalJson = parseResponse(json)
                return {
                    data: finalJson.data,
                    total: finalJson.total,
                }
            case GET_MANY:
                if (Array.isArray(json)) {
                    return { data: json }
                }
                return { data: [json] }
            case CREATE:
                return { data: { ...params.data, id: json.id } }
            case DELETE_MANY:
                return { data: [] }
            case DELETE:
                return { data: { id: '' } }
            default:
                if (json === null || json === undefined) {
                    return { data: {} }
                }
                return { data: json }
        }
    }

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
        const { url, options } = convertRESTRequestToHTTP(type, resource, params)
        return httpClient(url, options).then((response) => convertHTTPResponseToREST(response, type, resource, params))
    }
}
