import React, { useCallback, useState } from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Container from '@mui/material/Container'
import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'
import FactorAuthView from '../factor-auth-view'

export default function AccountView() {
    const settings = useSettingsContext()

    const [currentTab, setCurrentTab] = useState('security')

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue)
    }, [])

    return (
        <Container sx={{ mt: 1 }} maxWidth={settings.themeStretch ? false : 'xl'}>
            <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                sx={{
                    mb: { xs: 2, md: 2 },
                }}
            >
                <Tab
                    key="security"
                    label={'Güvenlik'}
                    icon={<Iconify icon="ic:round-vpn-key" width={24} />}
                    value="security"
                />
            </Tabs>

            {currentTab === 'security' && <FactorAuthView />}
        </Container>
    )
}
