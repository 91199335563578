import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import { useSearchParams } from 'src/routes/hooks'
import { useBoolean } from 'src/hooks/use-boolean'
import Iconify from 'src/components/iconify'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useTranslate } from 'react-admin'
import AuthClassicLayout from './login-layout'
import { useAuthService } from '../service/auth-service'
import LoginCodeView from './login-code-view'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { globalConfig } from '../global-config'
import { VerificationMethod } from '../service/types/user'

type Props = {
    onToken: (token: string, method: VerificationMethod) => void
}

function InternalLoginView({ onToken }: Props) {
    const [errorMsg, setErrorMsg] = useState('')
    const searchParams = useSearchParams()
    const password = useBoolean()
    const formRef = React.useRef<HTMLFormElement>(null)
    const service = useAuthService()
    const translate = useTranslate()
    const captchaRef = React.useRef<any>(null)
    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('Kullanıcı adınızı giriniz'),
        password: Yup.string().required('Şifrenizi giriniz'),
        captcha: Yup.string(),
    })
    const dataSiteKey = globalConfig.api.siteKey

    const defaultValues = {
        username: '',
        password: '',
        captcha: '',
    }

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    })

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    const onSubmit = handleSubmit(async (data) => {
        if (!data.captcha) {
            setErrorMsg('Captcha doğrulaması yapınız')
            return
        }
        await doLogin(data.username, data.password, data.captcha)
    })

    const doLogin = async (username: string, password: string, captcha: string) => {
        try {
            const token = await service.login(username, password, captcha)
            onToken(token.token, token.method)
        } catch (error) {
            setErrorMsg(translate(typeof error === 'string' ? error : error.message))
        }
    }

    useEffect(() => {
        if (searchParams.has('username')) {
            const username = searchParams.get('username')
            const password = searchParams.get('password')
            if (username && password) {
                reset({ username, password })
            }
        }
    }, [])

    const renderHead = (
        <Stack spacing={2} sx={{ mb: 5 }}>
            <Typography variant="h4">{'Admin Giriş Yap'}</Typography>
        </Stack>
    )

    const renderForm = (
        <Stack spacing={2.5}>
            {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

            <RHFTextField name="username" label={'Kullancı Adı'} />

            <RHFTextField
                name="password"
                label="Şifre"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <HCaptcha sitekey={dataSiteKey}
                      ref={captchaRef}
                      languageOverride={'tr'}
                      onLoad={() => {
                          captchaRef.current.execute()
                      }}
                      onVerify={(token) => {
                          setErrorMsg('')
                          methods.setValue('captcha', token)
                      }} />

            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                {'Giriş Yap'}
            </LoadingButton>
        </Stack>
    )

    return (
        <FormProvider methods={methods} formRef={formRef} onSubmit={onSubmit}>
            {renderHead}

            {renderForm}
        </FormProvider>
    )
}

export default function LoginView() {
    const [token, setToken] = useState('')
    const [verificationMethod, setVerificationMethod] = useState<VerificationMethod>()

    return <AuthClassicLayout title={'Nerde Kaldı Admin'}>
        {token && verificationMethod ? <LoginCodeView token={token} method={verificationMethod} /> : <InternalLoginView onToken={(token, method) => {
            setToken(token)
            setVerificationMethod(method)
        }} />}
    </AuthClassicLayout>
}


