import {
    AutocompleteArrayInput,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField, ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin'
import React from 'react'
import { ListPagination } from '../../common/list-pagination'
import Icon from '@mui/icons-material/ShoppingBasket'
import { OrderStatusLabel, PaymentTypeLabel } from '../customer/order-row'

export const OrderIcon = Icon

const OrderFilter = (props: any) => (
    <Filter {...props}>
        <TextInput name={'customerPhone'} source={'customerPhone'} label={'Müşteri Telefon'}  />
        <TextInput name={'id'} source={'id'}  label={'Sipariş No.'} />
        <ReferenceInput name={'marketId'} source={'marketId'} reference={'companies'} alwaysOn>
            <SelectInput optionText={'name'} label={'resources.companies.name'} />
        </ReferenceInput>
        <ReferenceInput name={'branchId'} source={'branchId'} reference={'branches'} alwaysOn>
            <SelectInput optionText={'name'} label={'resources.branches.name'} />
        </ReferenceInput>
        <ReferenceArrayInput name={'districts'}
                             source={'districts'}
                             reference={'districts'} alwaysOn>
            <AutocompleteArrayInput optionText={'name'}
                                    size={'small'}
                                    sx={{
                                        mb: '6px',
                                    }}
                                    filterToQuery={searchText => ({ name: searchText })}
                                    label={'resources.districts.name'} />
        </ReferenceArrayInput>
        <ReferenceArrayInput name={'towns'}
                             source={'towns'}
                             reference={'towns'} alwaysOn>
            <AutocompleteArrayInput optionText={'name'}
                                    size={'small'}
                                    sx={{
                                        mb: '6px',
                                    }}
                                    filterToQuery={searchText => ({ name: searchText })}
                                    label={'resources.towns.name'} />
        </ReferenceArrayInput>
    </Filter>
)

export const OrdersList = () => {
    return <List sort={{ field: 'pendingTime', order: 'DESC' }}
                 filters={<OrderFilter />}
                 exporter={false}
                 storeKey={false}
                 pagination={<ListPagination />}
                 perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={'id'} label={'#'} />
            <DateField source={'pendingTime'} showTime label={'Siparis Tarihi'} />
            <RecordOrderStatusLabel label={'Siparis Durum'} />
            <RecordPaymentTypeLabel label={'Odeme Tip'} />
            <NumberField source={'finalPrice'} label={'Fiyat (TRY)'} />
            <TextField source={'address'} label={'Adres'} />
            <ReferenceField reference={'customers'} source={'customer'}>
                <TextField source={'name'} />
            </ReferenceField>
            <ReferenceField reference={'companies'} source={'marketId'} label={'resources.companies.name'}>
                <TextField source={'name'} />
            </ReferenceField>
            <ReferenceField reference={'branches'} source={'marketId'} label={'resources.branches.name'}>
                <TextField source={'name'} />
            </ReferenceField>
        </Datagrid>
    </List>
}

const RecordOrderStatusLabel = ({ label }: { label: string }) => {
    const record = useRecordContext()
    if (!record) return <></>
    return <OrderStatusLabel status={record.status} />
}

const RecordPaymentTypeLabel = ({ label }: { label: string }) => {
    const record = useRecordContext()
    if (!record) return <></>
    return <PaymentTypeLabel type={record.paymentType} />
}